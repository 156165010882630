import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CREATEAPIURL, UMS_URL, UMS_SCOPES_URL, APP_NAME, environment } from '@env/environment';
import { MatSnackBar } from '@angular/material';
import { SharedService, center_list } from '../../../shared/services/shared.service';
import { SearchDataService } from '../../../dashboard/searchData/search-data.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
const MOBILE_SCREEN = 768;

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  currentPageTitle: string = '';
  setLayout: boolean = true;
  collapseSidebar: boolean = false;
  innerWidth: number;
  isMobileScreen = false;
  isCollapseSidebarOpen;
  selectedValue1: any;
  selectedValue2: any;
  selectedValue3: string;
  selectedValue4: string;
  optionSelected: any;
  url: any;
  ExtraParams: any;
  isneedtoshowSpinner: boolean;
  centerNameKey: string;
  languageKey: string;
  centerNameData: string;
  languageData: string;
  // pageData: string;
  centerCodeKey: string;
  centerCodeData: string;
  centersList: [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private service: SharedService,
    private searchDataService: SearchDataService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    private _cookieService: CookieService
  ) {
    this.getRouteParams();
    this.selectedValue2 = 'Waybills';
    this.selectedValue3 = 'CN_Shenzen_P';
    this.service.setSelectedLocation('CN_Shenzen_P');
    localStorage.setItem('center', this.selectedValue3);
    this.url = `${CREATEAPIURL}/search?action=waybill`;
    this.ExtraParams = '';
    this.optionSelected = 'Waybills';
    localStorage.setItem('page', this.optionSelected);

    this.getUserData();
    
  }

  getUserData() {
    this.searchDataService.getUserData(`${UMS_URL}/users/self/`).subscribe(response => {
      let fcs = response['user_data']['facility_id'] || [];
      this.centersList = center_list[environment.env].filter(item => fcs.includes(item.value));

      // localStorage.setItem("user_data", JSON.stringify(response));
      
    });
  }

  ngOnInit() {
    this.translate.setDefaultLang('en');
    this.centerNameKey = 'center';
    this.languageKey = 'language';
    this.centerCodeKey = 'centerCode';
    this.centerNameData = localStorage.getItem(this.centerNameKey);
    this.languageData = localStorage.getItem(this.languageKey);
    // this.pageData = localStorage.getItem('page');

    if (window.innerWidth <= MOBILE_SCREEN) {
      this.collapseSidebar = true;
      this.isMobileScreen = true;
    }

    // if (this.pageData === null) {
    //   localStorage.setItem('page', this.optionSelected);
    // } else {
    //   this.optionSelected = this.pageData;
    // }

    if (this.centerNameData === null) {
      localStorage.setItem(this.centerNameKey, this.selectedValue3);
    } else {
      this.selectedValue3 = this.centerNameData;
    }
    this.service.setSelectedLocation(this.selectedValue3);

    this.searchDataService.getData(`${CREATEAPIURL}/locationConfig?location=${this.selectedValue3}`).subscribe(response => {
      localStorage.setItem(this.centerCodeKey, response['payload'].location_code);
    });

    if (this.languageData === null) {
      this.ipLookUp();
    } else {
      this.selectedValue4 = this.languageData;
      this.translate.use(this.selectedValue4);
    }

    this.getAppScopes();
  }

  getAppScopes() {
    this.searchDataService.getUserData(UMS_SCOPES_URL).subscribe(
      res => {
        let permissionList = [];
        res['permissions'].forEach(permission => {
          if (permission && permission['app_name'] === APP_NAME && permission['scope']) {
            permissionList.push(permission['scope']);
          }
        });
        localStorage.setItem('scopes', JSON.stringify(permissionList));
      },
      error => {
        console.log(error);
      }
    );
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.selectedValue4 = language;
    localStorage.setItem(this.languageKey, this.selectedValue4);
  }

  ipLookUp() {
    this.searchDataService.getIpAddress().subscribe(data => {
      if (data.country === 'CN') {
        this.translate.use('cn');
        this.selectedValue4 = 'cn';
      } else {
        this.translate.use('en');
        this.selectedValue4 = 'en';
      }
      localStorage.setItem(this.languageKey, this.selectedValue4);
    });
  }

  getValue(event, val) {
    switch (val) {
      case 1:
        this.selectedValue1 = event.value;
        break;
      case 2:
        this.selectedValue2 = event.value;
        switch (this.selectedValue2) {
          case 'Waybills':
            this.url = `${CREATEAPIURL}/search?action=waybill`;
            this.optionSelected = 'Waybills';
            break;
          case 'bags':
            this.url = `${CREATEAPIURL}/search?action=bag`;
            this.optionSelected = 'Bags';
            break;
          case 'hawb':
            this.url = `${CREATEAPIURL}/search?action=hawb`;
            this.optionSelected = 'HAWB';
            break;
          case 'mawb':
            this.url = `${CREATEAPIURL}/search?action=mbag`;
            this.optionSelected = 'MAWB';
            break;
        }
        localStorage.setItem('page', this.optionSelected);
        break;
      case 3:
        this.selectedValue3 = event.value;
        localStorage.setItem(this.centerNameKey, this.selectedValue3);
        this.service.setSelectedLocation(this.selectedValue3);
        this.router.navigate(['./dashboard/apply-NSL', {params: this.selectedValue3} ]);
        break;
    }
  }

  callApi(event) {
    if (event.target.validationMessage !== '') {
      const msg = this.translate.instant('header.invalidationMessage');
      this.openSnackBar(msg, 'Warning');
      return;
    }
    if (event.target.value !== '') {
      const data = event.target.value.trim();
      const res = data.split(/[\s,]+/);
      const searchList = res.join(',');
      this.BodyParams(searchList);
      event.target.value = '';
    }
  }

  BodyParams(res) {
    const url = this.url + `&id=${res}`;
    this.ApiCallRequest(url);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 7000,
      verticalPosition: 'top',
      panelClass: 'mat-snack-bar-container'
    });
  }

  ApiCallRequest(url) {
    this.searchDataService.getData(url).subscribe(
      res => {
        if (
          res &&
          res['payload']
        ) {
          this.service.setResponce(res);
          this.router.navigate(['/dashboard/searchdata']);
        } else {
          const msg = this.translate.instant('header.noDatabase', {id: this.optionSelected});
          this.openSnackBar(msg, 'Warning');
        }
      },
      error => {
        const msg = this.translate.instant('header.noDatabase', {id: this.optionSelected});
        this.openSnackBar(msg, 'Error');
      }
    );
  }

  /**
   *
   * @description Method to get title and layout from Data sent when route changes.
   * @var event : has Layout and title. Layout defines whether we need sidebar and navbar.
   *  Title contains the title for page
   *
   **/
  getRouteParams() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.setLayout = event['layout'];
        this.currentPageTitle = event['title'];
        console.log('event',event)
        this.title.setTitle(`Delhivery | ${event['title'] ? event['title'] : 'Dashboard'}`);
      });
  }

  /**
   *
   * @description Method to handle logout, navigating user back to login page
   *
   **/
  onLogout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  /**
   *
   *	@description Method to handle toggle of sidebar on clicking on bars
   *
   **/
  toggleSidebar() {
    this.collapseSidebar = !this.collapseSidebar;
    this.isCollapseSidebarOpen = true;
  }

  goState() {
    console.log("in Go state");
    const domainName = '.delhivery.com';
    if (environment.env === 'prod') {
    console.log("Removing ums_token");
      this._cookieService.remove('ums_token', {domain: domainName, path: "/"});
    }
    localStorage.clear();
    location.reload();
  }

  closeSidebar(evt) {
    if (this.isMobileScreen && !evt) {
      if (this.collapseSidebar && !this.isCollapseSidebarOpen) {
        this.collapseSidebar = false;
      }
    }

    this.isCollapseSidebarOpen = false;
  }

  autoCloseSidebar($event) {
    if (window.innerWidth <= MOBILE_SCREEN) {
      this.collapseSidebar = true;
      this.isMobileScreen = true;
    }
  }
}

import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import { SharedService } from './../services/shared.service';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {
  @Input('appPermission') permissionName;
  userPermissions: any;

  constructor(
    private _elementRef: ElementRef,
    private service: SharedService
  ) { }

  ngOnInit() {
    this.checkPermission();
  }

  hasAllPermission(){
    return this.permissionName.every((permission:string) =>this.userPermissions.indexOf(permission) !== -1)
  }

  checkPermission() {
    let isPermitted;
    this.userPermissions = JSON.parse(localStorage.getItem('scopes'));

    if (this.permissionName) {
      isPermitted = this.hasAllPermission()
      
    }
    if (isPermitted) {
      //do nothing if permission is there
    } else {
      let el: HTMLElement = this._elementRef.nativeElement;
      let parentNode = el.parentNode;
      parentNode.removeChild(el);
    }
  }
}
import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Router,
  Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthService } from 'dlv-ng-auth';
import { CheckPermissionService } from './check-permission.service';
import { COMPONENT_PERMISSION } from './../../constants/permission.constants';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {
  COMPONENT_PERMISSION = COMPONENT_PERMISSION;

  constructor(
    private auth: AuthService,
    private router: Router,
    private checkPermissionService: CheckPermissionService
  ) { }

  canLoad(route: Route): Observable<boolean> {
    return this.checkLogin(`/${route.path}`, route.path);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const path = state.url.split('/')[2] || '';
    if (state.url === '/dashboard') {
      return this.redirectToFirstAccessibleRoute();
    }
    return this.checkLogin(state.url, path);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const path = state.url.split('/')[2] || '';
    if (state.url === '/dashboard') {
      return this.redirectToFirstAccessibleRoute();
    }
    return this.checkLogin(state.url, path);
  }

  private checkLogin(url: string, path: string): Observable<boolean> {
    const isLoggedIn = this.auth.isAuthenticated();
    console.log('logged in', isLoggedIn)
    if (url === '/' || url === '/forbidden') {
      return of(isLoggedIn);
    }

    if (url === '/login' && isLoggedIn) {
      this.navigateTo('dashboard');

      return of(true);
    } else if (url === '/login' && !isLoggedIn) {
      return of(true);
    } else if (isLoggedIn) {
      if (path && path !== '' && COMPONENT_PERMISSION[path]) {
        return this.checkPermissionService.checkAuthPermission(COMPONENT_PERMISSION[path]).pipe(
          tap(hasPermission => {
            if (!hasPermission) {
              this.navigateTo('forbidden');
            }
          }),
          catchError(() => {
            this.navigateTo('forbidden');
            return of(false);
          })
        );
      }
      return of(true);
    } else {
      this.navigateTo('login');
      return of(false);
    }
  }

  private navigateTo(route: string) {
    this.router.navigate([`/${route}`], { replaceUrl: true });
  }

  private redirectToFirstAccessibleRoute(): Observable<boolean> {
    return this.checkPermissionService.loadPermissions().pipe(
      map(userPermissions => {
        const routesWithPermissions = Object.keys(COMPONENT_PERMISSION);

        for (let route of routesWithPermissions) {
          if (COMPONENT_PERMISSION[route].every(permission => userPermissions.includes(permission))) {
            this.navigateTo(`dashboard/${route}`);
            return true;
          }
        }
        console.log('no urls found')
        this.navigateTo('forbidden');
        return false;
      }),
      catchError(() => {
        this.navigateTo('forbidden');
        return of(false);
      })
    );
  }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, from } from 'rxjs';
import { tap, map, catchError, switchMap, take, shareReplay } from 'rxjs/operators';
import { SearchDataService } from '../../dashboard/searchData/search-data.service';
import { UMS_SCOPES_URL, APP_NAME } from '@env/environment';
import { SharedService } from './../../shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class CheckPermissionService {
  // BehaviorSubject to store and track permission state
  private permissionsSubject = new BehaviorSubject<string[]>([]);
  // Observable that components can subscribe to
  public permissions$ = this.permissionsSubject.asObservable();
  // Flag to track if we've loaded permissions
  private permissionsLoaded = false;

  constructor(
    private service: SharedService,
    private searchDataService: SearchDataService,
  ) {}

  /**
   * Load permissions from API and store in service (not localStorage)
   */
  loadPermissions(): Observable<string[]> {
    // If permissions are already loaded, return the current value
    if (this.permissionsLoaded) {
      return this.permissions$.pipe(take(1));
    }

    // Otherwise, fetch from API
    return this.searchDataService.getUserData(UMS_SCOPES_URL).pipe(
      map(res => {
        const permissionList = [];
        res['permissions'].forEach(permission => {
          if (permission && permission['app_name'] === APP_NAME && permission['scope']) {
            permissionList.push(permission['scope']);
          }
        });
        localStorage.setItem('scopes',JSON.stringify(permissionList))
        return permissionList;
      }),
      tap(permissions => {
        this.permissionsSubject.next(permissions);
        this.permissionsLoaded = true;
      }),
      catchError(error => {
        console.error('Failed to load permissions:', error);
        return of([]);
      }),
      // Use shareReplay to share the result with multiple subscribers
      shareReplay(1)
    );
  }

  /**
   * Check if user has all the required permissions
   * Returns an Observable that resolves to boolean
   */
  checkAuthPermission(requiredPermissions: string[]): Observable<boolean> {
    return this.loadPermissions().pipe(
      map(userPermissions => {
        if (!requiredPermissions || requiredPermissions.length === 0) {
          return true;
        }
        
        // Check if user has all required permissions
        return requiredPermissions.every(permission => 
          userPermissions.includes(permission)
        );
      })
    );
  }

  checkPermission(permissionName) {
    let isPermitted:any = true;

    // this.dlvAuthService.getAppPermission(
    //   (scopes: Array<string | null>) => {
    //     localStorage.setItem('scopes', JSON.stringify(scopes));
    //   }
    // );
    
    const userPermissions = JSON.parse(localStorage.getItem('scopes'));

    if (permissionName) {
      for (let permissionIndex = 0; permissionIndex < permissionName.length; permissionIndex++) {
        if (userPermissions.indexOf(permissionName[permissionIndex]) === -1) {
          isPermitted = false;
          break;
        }
      }
    }
    return isPermitted;
  }



  /**
   * Synchronous version for immediate checks after permissions are loaded
   * Use with caution - only after permissions are confirmed to be loaded
   */
  hasPermission(requiredPermissions: string[]): boolean {
    const userPermissions = this.permissionsSubject.getValue();
    
    if (!requiredPermissions || requiredPermissions.length === 0) {
      return true;
    }
    
    return requiredPermissions.every(permission => 
      userPermissions.includes(permission)
    );
  }

  /**
   * Clear permissions (e.g., on logout)
   */
  clearPermissions(): void {
    this.permissionsSubject.next([]);
    this.permissionsLoaded = false;
  }
}